<template>
    <div class='newUsers card'>
        <h1>יצירת {{ descriptor }} חדש</h1>
        <div>
            <select class='userRole' v-model='user.role' @change='valid.role = true' :class='{ error: !valid.role }' :disabled='disabled || undefined'>
                <option value='' disabled hidden>סוג משתמש</option>
                <option v-for='({ display, name }) in userRoles' :value='name' :key='name'>{{ display }}</option>
            </select>
            <label>
                אי-מייל:
                <input v-model='user.email' @input='valid.email = true; user.emailExists = false' :class='{ error: !valid.email }' :disabled='disabled || undefined'/>
            </label>
            <label>
                מין:
                <select v-model='user.sex' @change='valid.sex = true' :class='{ error: !valid.sex }' :disabled='disabled || undefined'>
                    <option value=null disabled hidden>--------</option>
                    <option value='male' >זכר</option>
                    <option value='female' >נקבה</option>
                    <option value='other' >אחר</option>
                </select>
            </label>
            <label>
                <div id='nameLabel'>
                    <template v-if='user.role === "patient"'>
                        כינוי פנימי:
                        <tooltip maxWidth='11.5rem' placement='left'>
                            <template #default='slotProps'>
                                <span class='material-icons-round' v-bind='slotProps'>info</span>
                            </template>
                            <template #tooltip>
                                <center>כינוי פנימי שיאפשר לזהות את המטופל, אשר לא מכיל מידע מזהה כגון שם</center>
                            </template>
                        </tooltip>
                    </template>
                    <template v-else>שם מלא:</template>
                </div>
                <input v-model='user.name' :disabled='disabled || undefined'/>
            </label>
            <label>
                מספר טלפון:
                <input type='tel' v-model='user.phone' @change='valid.phone = true' :class='{ error: !valid.role }' :disabled='disabled || undefined'/>
            </label>
            <div class='message' v-show='user.emailExists'>
                כתובת המייל שרשמת כבר קיימת במערכת 
            </div>
            <div class='message' v-show='user.phoneExists'>
                מספר הטלפוו שרשמת כבר קיימת במערכת 
            </div>
            <div class='intakeIcon' v-show='user.role === "patient" && !disabled'>
                <div class='iconWrapper' :class='{ error: user.intakeEnabled && !valid.intake }'>
                    <img
                        :class='{ hasQuestions: user.intakeEnabled && Object.keys(user.intake).length }'
                        src='/images/questionnaire.jpg'
                        @click='goToSetIntake'
                    />
                </div>
                <switch-component v-model='user.intakeEnabled' @change='valid.intake = true' />
            </div>
            <submit-button
                v-show='!disabled'
                :text='`${$parse("צור","צרי")} משתמש`'
                :submitCondition='() => validate(row)'
                :submitCallback='() => create(row)'
                @submitted='user.disabled = true'
            />
            <div id='userInfo' v-if='disabled'>
                המשתמש נוצר בהצלחה. מעתה {{ user.sex === 'female' ? 'היא תוכל' : 'הוא יוכל' }}
                להתחבר למערכת באמצעות כתובת המייל שסיפקת.
            </div>
            <!-- <div id='userInfo' v-if='user.info.length && user.disabled'>
                המשתמש נוצר בהצלחה. {{ $parse('שלח', 'שלחי') }}{{ user.role === 'patient' ? ` ${user.sex === 'female' ? 'למטופלת' : 'למטופל'} ` : '' }}
                את הפרטים הבאים לשם התחברות ראשונה:
                <div>
                    <textarea v-model='user.info' />
                    <button class='material-icons-round' @click='copyLink'>content_copy</button>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import chooserModule, { setPreviousRoute, setSelected } from '@/SharedChart/Chooser/module';
import userCreateModule, { setIntake, setSettingIntake, setUserRole } from './module';
import userRoles from '@/assets/json/userTypes.json';
import { UserCreateState } from '@/ts/state/UserCreateState';

export default defineComponent({
    beforeMount(){
        if(this.$store.state.userCreate?.settingIntake)
            return setIntake();

        this.$store.registerModule('userCreate', userCreateModule);
        setUserRole(this.role ?? null);
    },
    data: () => ({ userRoles, disabled: false, valid: { email: true, sex: true, role: true, intake: true, phone: true }, }),
    computed: {
        role(){
            return this.$route.params.qualifier as UserCreateState['user']['role'];
        },
        user(){
            return this.$store.state.userCreate?.user;
        },
        descriptor(){
            return userRoles.find(r => r.name === this.role)?.display || 'משתמש';
        }
    },
    watch: {
        
    },
    methods: {
        async validate(){
            let out = true;
            const user = this.user!;
            const { sex, email, phone, role, intake, intakeEnabled } = user;
            
            const valid = {
                sex: sex !== null,
                role: Boolean(role),
                email: email !== null && email.length > 0 && email.includes('@'),
                phone: phone && /^[0-9]{10}$/.test(phone)
            }

            for(const key in valid){
                const v = key as keyof typeof valid;
                if(!valid[v])
                    out = this.valid[v] = false;
            }

            if(intakeEnabled && user.role && ['patient', 'anonymous'].includes(user.role) && !Object.keys(intake).length)
                out = this.valid.intake = false;
            return out;
        },
        async create(){
            let { sex, email, role, intakeEnabled, intake, name, phone } = this.user!;
            if(!intakeEnabled) intake = {};
            
            try{
                const response = await this.$request('/user/create','put', { email, name, phone, sex, role, intake, assign: true });
                if(response.body.success) this.disabled = true;
                // const initLink = `${window.location.origin}/signup/${response.body.token}`;
                // const info = `יצרתי בשבילך חשבון בשביל שאלוני המעקב. ${sex === 'female' ? 'הכנסי' : 'הכנס' } לקישור כדי להשלים את תהליך ההרשמה:\n\r${initLink}`
                // Object.assign(this.user, { info })
            }catch(error: any){
                if(error.message.includes('user already exists'))
                    this.user!.emailExists = true;
                console.error(error);
            }
        },
        goToSetIntake(){
            if(!this.$store.hasModule('chooser'))
                this.$store.registerModule('chooser', chooserModule);
            setSettingIntake(true);
            this.valid.intake = true;

            const { intake } = this.user!;
            if(intake.questions?.length)
                setSelected({ selected: intake.questions, legends: intake.legends || [] });
            setPreviousRoute(this.$route);
            this.$router.push('/users/setintake');
        },
        async copyLink() {
            // await navigator.clipboard.writeText(this.user!.info);
            // setToast({ text: 'ההודעה הועתקה בהצלחה', type: 'success' })
        }
    }
});
</script>

<style lang="scss">
.newUsers {
    height: fit-content;
    width: 30rem;
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    position: relative;
    flex-grow: 0 !important;

    & > div {
        position: relative;
        padding: 2rem 3rem 0;
    }

    & label {
        width: 100%;
        padding: 0.8rem 3rem; 
    }

    & input, & select:not(.userRole) {
        width: 100%;
        margin-top: .3rem;
    }

	& .userRole {
		position: absolute;
		left: 0.5rem;
		top: 1rem;
	}

	& .intakeIcon {
		position: absolute;
		right: 1rem;
		bottom: 1rem;
		padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center ;

        & .iconWrapper {
            border-bottom: 4px solid transparent;

            &.error {
                border: none !important;
                border-bottom: 4px solid red !important;
            }
        }

		& img {
			max-width: 3rem;
			cursor: pointer;
			filter: grayscale(100%) contrast(0.5);
			opacity: 0.3;
            margin-bottom: -0.5rem;
		}

		& .hasQuestions {
			filter: grayscale(0%);
			opacity: 1;
		}
	}

	& .add {
		margin: auto;
		max-width: 4rem;
		cursor: pointer;
	}

	& .message {
		margin: 1.5rem auto 0;
		max-width: 70%;
		text-align: center;
	}

	& > div > button {
		margin: 2rem auto 1.5rem;
        @include shadow;
        border-radius: $corner;
        padding: 0.5rem 1rem;
        background-color: $swatchB;

        &:hover {
            background-color: $hoverB;
        }
	}

    & #userInfo {
        padding: 2rem 0;
        width: 100%;
        margin: 0 auto;
        text-align: justify;

        & > div {
            margin-top: 1rem;
            display: flex;
            @include shadow;
            overflow: hidden;
            border-radius: $corner;

            border: 1px solid #eee;
            &:focus-within {
                border-color: #96b7d6;
            }

            & textarea {
                flex: 1;
                border-radius: 0;
                height: 8.5rem;
                box-shadow: none;
                margin: 0;
                border: none;
            }

            & button {
                background-color: #eee;
                border-right: 1px solid #ccc;
                color: #777;

                &:hover {
                    box-shadow: .2rem 0 1rem #ccc;
                }
            }
        }
    }

    #nameLabel {
        display: flex;
        align-items: center;

        & > span {
            margin-right: .5rem;
            font-size: 1.2rem;
        }
    }
}
</style>
