
import { defineComponent } from '@vue/runtime-core';
import chooserModule, { setPreviousRoute, setSelected } from '@/SharedChart/Chooser/module';
import userCreateModule, { setIntake, setSettingIntake, setUserRole } from './module';
import userRoles from '@/assets/json/userTypes.json';
import { UserCreateState } from '@/ts/state/UserCreateState';

export default defineComponent({
    beforeMount(){
        if(this.$store.state.userCreate?.settingIntake)
            return setIntake();

        this.$store.registerModule('userCreate', userCreateModule);
        setUserRole(this.role ?? null);
    },
    data: () => ({ userRoles, disabled: false, valid: { email: true, sex: true, role: true, intake: true, phone: true }, }),
    computed: {
        role(){
            return this.$route.params.qualifier as UserCreateState['user']['role'];
        },
        user(){
            return this.$store.state.userCreate?.user;
        },
        descriptor(){
            return userRoles.find(r => r.name === this.role)?.display || 'משתמש';
        }
    },
    watch: {
        
    },
    methods: {
        async validate(){
            let out = true;
            const user = this.user!;
            const { sex, email, phone, role, intake, intakeEnabled } = user;
            
            const valid = {
                sex: sex !== null,
                role: Boolean(role),
                email: email !== null && email.length > 0 && email.includes('@'),
                phone: phone && /^[0-9]{10}$/.test(phone)
            }

            for(const key in valid){
                const v = key as keyof typeof valid;
                if(!valid[v])
                    out = this.valid[v] = false;
            }

            if(intakeEnabled && user.role && ['patient', 'anonymous'].includes(user.role) && !Object.keys(intake).length)
                out = this.valid.intake = false;
            return out;
        },
        async create(){
            let { sex, email, role, intakeEnabled, intake, name, phone } = this.user!;
            if(!intakeEnabled) intake = {};
            
            try{
                const response = await this.$request('/user/create','put', { email, name, phone, sex, role, intake, assign: true });
                if(response.body.success) this.disabled = true;
                // const initLink = `${window.location.origin}/signup/${response.body.token}`;
                // const info = `יצרתי בשבילך חשבון בשביל שאלוני המעקב. ${sex === 'female' ? 'הכנסי' : 'הכנס' } לקישור כדי להשלים את תהליך ההרשמה:\n\r${initLink}`
                // Object.assign(this.user, { info })
            }catch(error: any){
                if(error.message.includes('user already exists'))
                    this.user!.emailExists = true;
                console.error(error);
            }
        },
        goToSetIntake(){
            if(!this.$store.hasModule('chooser'))
                this.$store.registerModule('chooser', chooserModule);
            setSettingIntake(true);
            this.valid.intake = true;

            const { intake } = this.user!;
            if(intake.questions?.length)
                setSelected({ selected: intake.questions, legends: intake.legends || [] });
            setPreviousRoute(this.$route);
            this.$router.push('/users/setintake');
        },
        async copyLink() {
            // await navigator.clipboard.writeText(this.user!.info);
            // setToast({ text: 'ההודעה הועתקה בהצלחה', type: 'success' })
        }
    }
});
