import { namespacedToCommit, namespacedToDispatch } from "@/helpers/store";
import { State } from "@/ts/state/State";
import { UserCreateState } from "@/ts/state/UserCreateState";
import { ActionContext } from "vuex";

const initialState = (): UserCreateState => ({
    user: {
        anonymous: false,
        role: '',
        name: null,
        phone: null,
        email: null,
        emailExists: false,
        sex: null,
        intake: {},
        intakeEnabled: false
    },
    settingIntake: false
});

const mutations = {
    setSettingIntake(state: UserCreateState, val: boolean){
        state.settingIntake = val;
    },
    setIntake(state: UserCreateState, intake: UserCreateState['user']['intake']){
        state.user.intake = intake;
    },
    setUserRole(state: UserCreateState, role: UserCreateState['user']['role']){
        state.user.role = role;
    },
    clearState(state: UserCreateState){
        Object.assign(state, initialState());
    }
}

const toCommit = namespacedToCommit('userCreate');
const toDispatch = namespacedToDispatch('userCreate');
export const setSettingIntake = toCommit(mutations.setSettingIntake);
export const setUserRole = toCommit(mutations.setUserRole);
export const commitSetIntake = toCommit(mutations.setIntake);
export const clearState = toCommit(mutations.clearState);

const actions = {
    setIntake({ rootState }: ActionContext<UserCreateState, State>){
        commitSetIntake({ questions: rootState.chooser!.selected, legends: rootState.chooser!.legends });
        setSettingIntake(false);
    }
}

export const setIntake = toDispatch(actions.setIntake);

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions
}
